import getters from '@/views/alerts/mixins/getters';

export default {
  mixins: [getters],
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    isFetching: {
      type: Boolean,
    },
    pagination: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['show', 'table-change'],
  methods: {
    showDetail(record) {
      this.$emit('show', record);
    },
    handleTableChange(pagination, filters, sorter) {
      this.$emit('table-change', pagination, filters, sorter);
    },
  },
};
