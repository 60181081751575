<template>
  <Delete
    action-handler="alerts/delete"
    :payload-value="record.id"
    :title="$t('title')"
    :description="$t('desc', { title: record.title })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { title: record.title })"
    @close="$emit('delete')"
  />
</template>

<i18n>
{
  "en": {
    "title": "Delete Alert?",
    "desc": "Are you sure you want to delete alert {title}?",
    "deleted-title": "Alert deleted",
    "deleted-desc": "Alert {title} has been removed."
  },
  "ja": {
    "title": "通知を削除?",
    "desc": "本当に {title} を削除しますか？",
    "deleted-title": "通知削除完了",
    "deleted-desc": "通知 {title} が削除されました"
  }
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteAlert',
  components: {
    Delete,
  },
  props: {
    record: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
};
</script>
